// import npm packages
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { Input, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { CognitoIdentityServiceProvider } from 'aws-sdk';

import { requestSlugData } from './../../data/action';
import withRouter from './../../../../shared/withRouter';
import * as validate from './../../../../shared/validators';
import * as config from './../../../../shared/config';
import { handleCognitoError } from './../../../../shared/handleCognitoError';
import { requestPostForgotPassword } from './../../../homeowner/actions/forgetPasswordAction';
import { envImageUrl, checkImageValid, convertJSON2CSS } from '../setUserBackgroud';

const style = {
  error: {
    margin: '8px',
    color: 'rgb(200,0,0)',
    width: '80%',
    fontSize: '15px'
  }
};

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

class ForgotPassword extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    forgotPassword: PropTypes.func.isRequired,
    auth: PropTypes.object,
    init: PropTypes.func,
    history: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.cognitoIdentityServiceProvider = new CognitoIdentityServiceProvider();
    this.loader = false;
    this.image = '';
    // this.emailError = undefined;
    this.slug = '';
    this.state = {
      email: '',
      emailError: undefined
    };
  }
  componentWillMount() {
    this.slug = localStorage.getItem('slug');
    convertJSON2CSS(this.slug).then((r) => {
      this.companyName = r;
    });
    this.props.init();
    if (this.slug) this.props.requestSlugData(this.slug);

    if (this.props.router.location.state && this.props.router.location.state.expiredCode) {
      this.setState({
        emailError: 'Your password reset code has expired. Please request a code again.'
      });
      if (this.slug) {
        this.props.router.navigate(`/${this.slug}/forgotpassword`);
      } else {
        this.props.router.navigate(`/forgotpassword`);
      }
    }
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.image && nextProps.image.status === 200) {
      this.image = nextProps.image.data.logo;
    }
    if (
      nextProps.auth.error &&
      nextProps.auth.error.code === 'NotAuthorizedException' &&
      !this.state.notifyHomeowner
    ) {
      this.setState({ notifyHomeowner: true }, () => {
        nextProps.requestPostForgotPassword(this.state.email);
      });
    }
  }

  componentDidUpdate() {
    if (this.props.auth.error && this.props.auth.error.message) {
      this.loader = false;
    }
  }

  componentDidMount() {
    if (this.slug) {
      envImageUrl(this.slug).then((response) => {
        if (response && !this.state.defaultLoginImage) {
          this.setState({
            defaultLoginImage: response
          });
        }
      });
    } else {
      this.setState({
        defaultLoginImage:
          'https://prod-17terawatts.s3.amazonaws.com/public/logo/desktopLogin_BG.jpg'
      });
    }
  }

  forgotPassword = (values) => {
    if (this.state.email.length === 0) {
      this.setState({ emailError: 'Please enter your email first.' });
    } else if (this.state.email.length) {
      this.setState({ emailError: undefined });
    }
    if (this.state.email) {
      this.loader = true;
      this.props.forgotPassword(this.state.email);
    } else this.emailError = config.EMPTY_EMAIL;
  };

  onChangeEmail = (e) => {
    if (this.state.email.length) {
      this.setState({ emailError: undefined });
    }
    if (e.target.value !== '') this.emailError = validate.checkEmail(e.target.value);
    else this.emailError = undefined;

    this.setState({ email: e.target.value.trim().toLowerCase() });
  };

  handleReload = () => {
   console.log("handleReload");
    if (this.slug)
      this.props.router.navigate('/' + this.slug + '/login/', {});
    else
      this.props.router.navigate('/login/', {});
    window.location.reload();
  }

  render() {
    const { handleSubmit, auth, homeownerResendInvitation } = this.props;
    const image = this.props.image;
    if (auth.error && auth.error.message) this.loader = false;
    let css = {
      backgroundImage: `url(${this.state.defaultLoginImage})`
    };

      if (auth.hasSentCode !== 'AUTH_SUCCESS') {
        return (
          <React.Fragment>
            <Spin spinning={this.loader} indicator={antIcon} style={{ color: 'white' }}>
              <title>{this.companyName || 'Bodhi'} - Forget Password</title>
              <div id="test-bg" className="login-bg body-height" style={css}>
                <div id="login-container" className="h-100">
                  <div className="login-box ">
                    <div className="d-inline-block justify-content-end w-100 text-right">
                      <div className="white-logo d-inline-block" />
                    </div>

                    <div className="box-Shadow center-white-box">
                      <div className="forgotpassword">
                        <div
                          className="logo-blue"
                          style={{
                            backgroundImage: `url(${
                              (image && image.data && image.data.mobileLogo) || ''
                            })`
                          }}
                        />
                        <p className="mobile-h2">Let’s reset your password</p>
                        <form action="reset-password.html">
                          <div className="form-group">
                            <Input
                              ptype="email"
                              className="form-control login-email"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder="Email"
                              onChange={this.onChangeEmail}
                            />
                          </div>
                          {this.state.emailError ? (
                            <div style={style.error}>{this.state.emailError}</div>
                          ) : (
                            ''
                          )}


                          {auth.error &&
                            auth.error.message ===
                            'User password cannot be reset in the current state.' ? (
                            <p style={style.error}>
                              Sorry, your previous temporary password has expired. I just emailed you
                              new login credentials.
                            </p>
                          ) : (
                            ''
                          )}
                         

                          {auth.error && this.state.email.length ? (
                            <div style={style.error}>
                              {JSON.stringify(auth.error) !== '{}' &&
                              auth.error.code !== 'NotAuthorizedException'
                                ? handleCognitoError(auth.error.code, auth.error.message)
                                : null}
                            </div>
                          ) : (
                            ''
                          )}


                          {auth.error &&
                            auth.error.message ===
                            'User password cannot be reset in the current state.' ? (
                              <div className="text-center">
                              <button
                                className="btn yellow-btn premiumButtonFill premiumButtonText login-text"
                                onClick={()=>this.handleReload()}>
                                Continue
                              </button>
                            </div>
                          ) : (
                            <div className="text-center">
                            <button
                              className="btn yellow-btn premiumButtonFill premiumButtonText login-text"
                              onClick={handleSubmit(this.forgotPassword)}>
                              Continue
                            </button>
                          </div>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
          </React.Fragment>
        );
      } else {
        if (this.slug)
          this.props.router.navigate('/' + this.slug + '/resetpassword/' + this.state.email, {
            email: this.state.email
          });
        else
          this.props.router.navigate('/resetpassword/' + this.state.email, {
            email: this.state.email
          });
        return null;
      }
  }
}

// Decorate the form component
const ForgotPasswordComponent = reduxForm({
  form: 'forgotPassword'
})(ForgotPassword);

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestSlugData,
      requestPostForgotPassword
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(withRouter(ForgotPasswordComponent));
